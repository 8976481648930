import { computed } from "vue";
import { useRouter } from "vue-router";
import { secureLocalStorage } from "@/graphql/utils/encrypt-storage";
import { activeActivity } from "@/plugins/i18n";
import { canNavigateAnywhere, getAccess } from "@/utils/activity";
import { EAccessMenu } from "@/graphql/types";

const ACTIVITY_INDEX = "ACTIVITY_INDEX";
const tabs = ["settings", "terminals", "roles"];

export const useActivity = () => {
  const acc = getAccess(EAccessMenu.Terminal);
  const noRestriction = canNavigateAnywhere();
  const { push, currentRoute } = useRouter();
  const active = computed(() => {
    if (!activeActivity.value.id) return 0;

    const tab = currentRoute.value.query["tab"];

    if (tab) {
      switch (tab) {
        case "settings":
          return 0;
        case "terminals":
          return 1;
        default:
          return 2;
      }
    }
    const saved = secureLocalStorage.getItem(ACTIVITY_INDEX);
    return parseInt(saved) || 0;
  });
  const noActivity = computed(() => !activeActivity.value.id);
  const permissions = computed(() => {
    const canReadTerminal = acc?.read || noRestriction;
    return {
      canReadTerminal,
      noRestriction,
      activityOnly: !(noRestriction || canReadTerminal),
    };
  });

  function saveActive(index: number) {
    secureLocalStorage.setItem(ACTIVITY_INDEX, index);
    push({ path: "/workspace/activity", query: { tab: tabs[index] } });
  }

  return { active, saveActive, noActivity, permissions };
};
